import React from 'react';
import { Box,  Typography } from '@mui/material';
import { PRODUCTS } from '../../../constants';

const ProductsField = ({products}) => {

    return (
        <Box gridColumn='controls' display='flex' flexDirection='column' gap='16px'>
        {
            PRODUCTS.map((product) => {
                if (products[product.name] > 0) {
                    return <React.Fragment key={`productType${product.name}`}>
                        <Typography variant='primary' paragraph='false' sx={{borderBottom:'1px solid #18365D', mb:'0px'}}>{`${products[product.name]} ${product.title} (${product.size})`}</Typography>
                    </React.Fragment>
                }
            })
        }
        </Box>
    );
};

export default ProductsField;