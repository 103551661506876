import React, { useEffect, useState }from 'react';
import { Box, IconButton, ImageListItem, Modal, Typography} from '@mui/material';
import { imageListItemClasses } from '@mui/material/ImageListItem';
import { Close } from '@mui/icons-material';
import { getAllImages } from '../../airtable/imageGallery';
import Loader from '../../sharedComponents/loader';
import ContentBlock from '../../sharedComponents/contentBlock';

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    outline: 0,
    backgroundColor: 'white'
  };

const ImageGallery = () => {
    const [images, setImages] = useState([]);
    const [isLoading, setLoading] = useState(true);

    
    useEffect(() => {
        getAllImages().then(res => {
          setImages(res);
          setLoading(false);
        });
    }, []);

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('false');

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleImage = (item) => {
        setImage(item);
        setOpen(true);
    };

    return (
        <>
        {
            isLoading ? 
            <ContentBlock>
                <Loader />            
            </ContentBlock> 
            :
            <>
            <Box sx={{display: {xs:'none', sm: 'block'}}}>
                <ContentBlock>
                    <Typography variant='paragraph'>Click on the images for a closer look.</Typography>
                </ContentBlock>
            </Box>
            <ContentBlock bgcolor='secondary.main'>
                <Box
                sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                },
                gap: {sm: 5, xs: 2},
                [`& .${imageListItemClasses.root}`]: {
                    display: 'flex',
                    flexDirection: 'column'
                }
                }}
            >
                {images.map((item) => (
                    <>
                        <Box sx={{boxShadow: '0px 3px 4px 4px rgba(0, 0, 0, 0.05)', display: { xs: 'none', sm: 'block' }}}>
                        <ImageListItem key={item.img}>
                            <img
                                src={`${item.imageUrl}?h=380&fit=crop`}
                                alt={item.title}
                                loading='lazy'
                                onClick={(e) => handleImage(item)}
                            />
                        </ImageListItem>
                        </Box>
                        
                        <Box sx={{display: { xs: 'block', sm: 'none' }}}>
                        <ImageListItem key={item.img}>
                            <img
                                src={`${item.imageUrl}?h=380&fit=crop`}
                                alt={item.title}
                                loading='lazy'
                            />
                        </ImageListItem>
                        </Box>
                    </>
                    
                ))}
            </Box>
            </ContentBlock>        

            </>
        }
            <Modal
            id='modal-img'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            >
                <Box style={boxStyle} sx={{width: {xs: 'auto', md: 'auto'}, height: {xs: 'auto', md: '90vh'}, maxHeight: {xs: '90vh', md: 'auto'}, maxWidth: {xs:'90vw', md: 'auto'}}}>
                    <Box width='100%' height='50px' backgroundColor='blue.light' position='relative'>
                        <IconButton onClick={handleClose} 
                            sx={{p:0, position:'absolute', top:'50%', right:10, transform: 'translateY(-50%)', backgroundColor:'blue.periwinkle', borderRadius: '10%', '&:hover': {
                                backgroundColor: 'blue.periwinkle'
                            }}}
                        >
                            <Close sx={{color: 'white'}}/>
                        </IconButton>
                    </Box>
                    <img
                        src={image.imageUrl}
                        alt={image.title}
                        style={{
                            maxWidth: '100%',
                            maxHeight: 'calc(90vh - 50px)',
                        }}
                    />   
                </Box>
            </Modal>
    </>
    );
};

export default ImageGallery;