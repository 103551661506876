import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import logo from '../images/logo.png';


const Footer = () => {
    return (
        <Box position='static' sx={{ bottom: 0, py:'10px', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:'5px'}}>
                <Typography>made by: </Typography>
                <img src={logo} alt='logo' height='20px'/>
            </Box>
            {/* <Typography variant='caption'>Developed by Natisha Khadgi</Typography> */}
            <Link href='https://github.com/natishakhadgi' variant='caption' sx={{color:'black'}}>Developed by Natisha Khadgi</Link>
        </Box>
    );
};

export default Footer;