// Import Images
import cardLandscape from './images/cardLandscape.png';
import cardPortrait from './images/cardPortrait.png';
import postcardLandscape from './images/postcardLandscape.png';
import postcardPortrait from './images/postcardPortrait.png';

import illustrationImg from './images/illustration.png';
import typographyImg from './images/typography.png';
import cardDesignImg from './images/cardDesign.png';

import latestDesign01 from './images/latestDesigns01.png';
import latestDesign02 from './images/latestDesigns02.png';
import latestDesign03 from './images/latestDesigns03.png';

import mothersDay from './images/mothersDayRecreation.png';
import mothersDayOriginal from './images/mothersDayOriginal.png';
import graduationApart from './images/graduationApart.png';
import graduationApartOriginal from './images/GraduationOriginal.png';
import galentinesRelations from './images/galentinesRelations.png';
import galentinesOriginal from './images/galentinesOriginal.png';

import mothersDayEx from './images/exampleMobile01.png';
import graduationEx from './images/exampleMobile02.png';
import galentinesEx from './images/exampleMobile03.png';

// Styling Constants
export const spacing30 = '30px';
export const spacing35 = '35px';


// Airtable
export const maxRecords = 12;

// Home Page
export const CARD_DESIGN_PROCESS = [
    {
        img: illustrationImg,
        title: 'Illustration',
        subtitle: 'Based on reference photos, we will illustrate you and your loved ones.'
    },
    {
        img: typographyImg,
        title: 'Typography',
        subtitle: 'We will hand-letter the message you want to express.'
    },
    {
        img: cardDesignImg,
        title: 'Card Design',
        subtitle: `We will add color and flourishes to finish your custom design.`
    },
];

export const LATEST_IMGS = [latestDesign01, latestDesign02, latestDesign03];

export const HOME_HERO = 'We design custom cards & postcards for you to send to your loved ones.';
export const HOME_INTRO = 'Whether you want to send a birthday card or celebrate an anniversary, let us know your mission and we’ll create something special for your loved ones.';

// About Page
export const BLURB = `We’re Ally & Alison - two friends who love creating and connecting with others. Ally is a web designer and illustrator by trade, and Alison is a self-taught hand-lettering artist. After graduating from college together during the COVID-19 pandemic, we began Dispatch Ko. as a passion project.

We know how important it is to be there for people we love, even when we’re not physically able to, and to celebrate every milestone with them. Through our designs, we hope to capture your heart and story, so that you can send a meaningful card to the most important people in your life.`;

export const CONTACT_BLURB = 'Follow us on Instagram - we’d love to meet you!'

// Products Page
export const PRODUCTS_BLURB = 'Our designs come in landscape or portrait layouts at one size.';

export const PROCESS = [
    'Fill out our online form and include photos.',
    'After ~1-5 business days, we’ll email you an order receipt including a rough sketch.',
    'Pay the full deposit through Paypal or Venmo to continue. Please email us if you would prefer cash or check and you live in the East Bay Area in California.',
    'After ~7-9 business days, you can send one free email of revisions. Other revisions cost +$10 each.',
    'We’ll print and mail the final product to you so you can write and send to your loved ones!'
];

export const FAQ = [
    {
        question: 'How much do your products cost?',
        answer: 'Each unique product design is $10. Additional copies of the same product design will be +$3. A new product design is +$10.'
    },
    {
        question: 'What kinds of photos do you accept?',
        answer: 'Ideally, we’d love high-quality, brightened, color-accurate images of people and animals, including the exact hairstyles, clothing, height differences, and facial expressions.\nIf you don’t feel like you have the perfect photo that captures your vision for this product, please be as descriptive as possible and include as many helpful images as you can. We’ll use our imagination and creativity to do the rest!'
    },
    {
        question: 'How long does this process typically take?',
        answer: 'It normally takes us around 7-9 days to design the card. The card delivery depends on the mail carriage, and arrival averages 1-4 days after the postage date.'
    },
    {
        question: 'Can I request a card and receive it before a specific date?',
        answer: 'You can let us know through our order form, but it is not guaranteed that we can meet your deadline, especially if we are working on multiple orders at once. The best thing you can do is to place your order well in advance!'
    },
    {
        question: 'What payment types are acceptable?',
        answer: 'We accept Venmo, Paypal, Cash, or Check. Cash or Check works best if you’re picking up your card in person.'
    },
]

export const VIEW_EXAMPLES = [
    {
        img: mothersDay,
        title: `Mother’s Day Photo Recreation`,
        originalImg: mothersDayOriginal,
        example: mothersDayEx,
        description: `Description: This is a Mother’s Day card where our client requested this photo recreation without the dad 
        in the middle and without her sister’s hat with a floral or springtime background.`
    },
    {
        img: graduationApart,
        title: `Graduation Celebration Apart`,
        originalImg: graduationApartOriginal,
        example: graduationEx,
        description: `Description: This is a graduation card for a friend. Since the trio had never taken any grad pics due to Covid-19 restrictions, we recreated this photo to celebrate the 
        work they did together during college.`
    },
    {
        img: galentinesRelations,
        title: `Galentine’s Day Relations`,
        originalImg: galentinesOriginal,
        example: galentinesEx,
        description: `Description: This is a Galentine’s Day card for a friend whose best friends live farther away and are entering new life stages. She wanted postcards to celebrate their adventures past and to come.`
    },
];

// Order Agreements
export const AGREEMENTS_BLURB = 'We’re honored that we get to make something special for you and your loved ones. Please read the following guidelines that we request all customers to follow BEFORE filling out the rest of the order sheet - thank you!';
export const AGREEMENTS = [
    'Your designs can be posted on our social media unless you say otherwise. If you do not want your designs posted, design elements of the card, such as layout & message, may be replicated for future designs.',
    'We reserve the right to decline projects if we are uncomfortable with the content or have too many orders.',
    'We are not at fault if cards are lost in the mail. We will do our best to replace anything damaged, so send us a pic through email!',
    'You must provide a description and the right photos in order for us to create the designs you’re looking for. Otherwise we will decline the project.',
];

// Order
export const MIN = 0;
export const MAX = 20;
export const emptyOrder = {
    // id: 0,
    productMsg: '',
    productDesc: '',
    products: 
    {
        cardLandscape: 0,
        cardPortrait: 0,
        postcardLandscape: 0,
        postcardPortrait: 0,
    },
    digitalCopy: 'No',
    id: -1,
};

export const PRODUCTS = [
    {
        img: cardLandscape,
        title: 'Card Landscape',
        size: '5.5 x 4.25”',
        name: 'cardLandscape'
    },
    {
        img: postcardLandscape,
        title: 'Postcard Landscape',
        size: '5.5 x 4.25”',
        name: 'postcardLandscape'
    },
    {
        img: cardPortrait,
        title: 'Card Portrait',
        size: '4.25 x 5.5”',
        name: 'cardPortrait'
    },
    {
        img: postcardPortrait,
        title: 'Postcard Portrait',
        size: '4.25 x 5.5”',
        name: 'postcardPortrait'
    }
];

export const productMsgPlaceholder = `Examples: “Happy Galentines”, “So proud of you, congrats!”`;
export const productDescriptionPlaceholder = `Example: “Because of the pandemic I won’t be able to be with my girls in person during the holidays. To show them that I’m thinking of them, I would like you to create a photo of us posing together in these outfits! Everyone is the same height except for the joanne.png, who’s about 2 inches taller. Thanks for helping me share my appreciation to my gals!”`;

// Order Review
export const ordersTemplate = [
    {
        title: 'Product Message:',
        field: 'productMsg'
    },
    {
        title: 'Product Illustration:',
        field: 'productDesc'
    },
    {
        title: 'Product Type:',
        field: 'products'
    },
    {
        title: 'Digital Copy:',
        field: 'digitalCopy'
    }
];

export const contactTemplate = [
    'Your First and Last Name:',
    'Your Mailing Address:',
    'Your Email Address:'
];

export const contactPlaceholder = [
    {
        label: 'Name',
        placeholder:  'Ian Kwan'
    },
    {
        label: 'Address',
        placeholder:  '1234 Mail Here Boulevard, City, State 98765'
    },
    {
        label: 'Email',
        placeholder:  'youremail@gmail.com'
    }
];

export const emptyClientInfo = {
    name: '',
    address: '',
    email: ''
};

// Order Submitted
export const SUBMIT_BLURB = 'We will email you an order receipt & sketch in a couple of days. Then you can pay your deposit online and we’ll start the final.'

// Errors
export const PRODUCT_MSG_ERR_EMPTY = 'Include a product message. We recommend 5 words max.';
export const PRODUCT_DESC_ERR_EMPTY = 'Include a description. The more detailed, the better!';
export const PRODUCTS_ERR = 'Select at least one product.';
export const IMAGES_ERR = 'Upload at least one image.';

export const PRODUCT_MSG_SUBTITLE = '*We recommend 5 words max.';
export const PRODUCT_DESC_SUBTITLE = '*Please name your files and reference them in the description below.';
export const PRODUCT_SUBTITLE = '*One product is $10 (shipping included). Additional copies are +$3.';

export const PRODUCT_MSG_ERR_TITLE = 'Message is blank. *We recommend 5 words max.';
export const PRODUCT_DESC_ERR_TITLE = 'Description is blank. *Please name your files and reference them in the description below.';
export const CHOOSE_PRODUCT_ERR_TITLE = 'No products were chosen. *One product is $10 (shipping included). Additional copies are +$5.';
export const IMAGE_MSG_ERR_TITLE = 'No files uploaded. Please upload your named files below.';

export const NAME_ERR = 'Please enter a name.';
export const ADDRESS_ERR = 'Please enter an address.';
export const EMAIL_ERR = 'Please enter a valid email.';
