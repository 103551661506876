import { createTheme, responsiveFontSizes } from '@mui/material';

let theme = createTheme (
  {
    typography: {
      primary: {
        fontFamily: [
          'Poppins',
          'sans-serif',
        ].join(','),
        fontWeightLight: 200,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
      },
      h1:{
          fontFamily: 'Pacifico',
          fontSize: 69,
          fontWeight: 400,
          color: '#17365E',
      },
      h2:{
          fontFamily: 'Pacifico',
          fontSize: 56,
          fontWeight: 400,
          color: '#17365E',
      },
      h3:{
          fontFamily: 'Poppins',
          fontSize: 28,
          fontWeight: 600,
          color: '#18365D',
      },
      title: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 24,
        color: '#18365D'
      },
      subtitle: {
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 21,
          color: 'black'
      },
      introText: {
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 18,
          color: '#18365D'
      },
      paragraph: {
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 16,
          color: 'black'
      },
      imgTitle: {
        fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 16,
          color: 'black'
      }
    },
    palette: {
      primary: {
        main: '#FFFFFF', // fix this palette!
        contrastText: '#17365E',
        error: '#AE0808'
      },
      secondary: {
        main: '#FEF0EC',
        light: '#E89F90',
        dark: '#D96F5D',
        contrastText: '#18365D',
      },
      blue: {
        periwinkle: '#474E9B',
        light: '#E1EEF9',
        dark: '#18365D'
      }
    },
    overrides: {      
    },
  }
);

theme = responsiveFontSizes(theme);

export default theme;