import React from 'react';
import { Box, Typography } from '@mui/material';
import NumberedListIcon from '../../../sharedComponents/numberedListIcon';

const ProcessStep = (props) => {
    return (
        <Box sx={{
            display:'flex',
            flexDirection: {xs: 'column', md: 'row'},
            backgroundColor: 'white',
            mt: props.margin,
            p: 2,
            gap: 2,
            alignItems: {xs: 'center', md: 'start'},
            width: {sm: props.width, xs: 'auto'},
            zIndex: 100
        }}>
            <NumberedListIcon step={props.index}/>
            <Typography variant='subtitle'  sx={{color: 'secondary.contrastText'}}>{props.step}</Typography>
        </Box>
    );
};

export default ProcessStep;