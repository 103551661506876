import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const ContentBlock = (props) => {
    return (
        <Box sx={{bgcolor: props.bgcolor}}>
        <Grid item container>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} id={props.id} 
                sx={{
                    display:'flex', 
                    flexDirection:'column', 
                    alignItems:'center', 
                    justifyContent:'center', 
                    py: {xs: props.py || 3, md: props.py || 6},
                    pt: props.pt,
                    pb: {xs: props.pb || 3, md: props.pb || 6},
                }}
            >
                <Typography variant='h3' sx={{mb: props.title && 3}}>{props.title}</Typography>
                {props.children}
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
        </Grid>
        </Box>
    );
};

export default ContentBlock;