import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const OrderError = ({isError, setError, errors}) => {
    return (
        <Dialog
            open={isError}
            onClose={() => setError(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
        <DialogTitle id='alert-dialog-title'>
          {'Unable to create order.'}
        </DialogTitle>
        <DialogContent>
            {
                errors.map((err, idx) => {
                    return (
                        <DialogContentText key={`err_${idx}`}>{err.msg}</DialogContentText>
                    )
                })
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(false)} sx={{color:'black'}}>Okay.</Button>
        </DialogActions>
      </Dialog>
    );
};

export const SubmitOrderError = ({isClientError, setClientError, clientErrors}) => {
  return (
    <Dialog
        open={isClientError}
        onClose={() => setClientError(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Unable to submit order.'}</DialogTitle>
      <DialogContent>
        {clientErrors.map((err, idx) => { return <DialogContentText key={`contact_err_${idx}`}>{err.msg}</DialogContentText> })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setClientError(false)} sx={{color:'black'}}>Okay.</Button>
      </DialogActions>
    </Dialog>
  );
};

export const RemoveOrderError = ({isRemovingLastOrder, SetIsRemovingLastOrder}) => {
  return (
    <Dialog
        open={isRemovingLastOrder}
        onClose={() => SetIsRemovingLastOrder(false)}
        aria-labelledby='remove-order-err'
    >
      <DialogTitle id='alert-dialog-title'>{'No Orders Left.'}</DialogTitle>
      <DialogContent>
          <DialogContentText>You will have no orders left if you remove this order. You can choose not to order anything, or continue to review your order.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => SetIsRemovingLastOrder(false)} href={`./order`} sx={{color:'black'}}>Remove Order.</Button>
        <Button onClick={() => SetIsRemovingLastOrder(false)} sx={{color:'black'}}>Review Order.</Button>
      </DialogActions>
    </Dialog>
  );
};

