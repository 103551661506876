import React from 'react';
import { Box, ImageListItem, Typography } from '@mui/material';
import { imageListItemClasses } from '@mui/material/ImageListItem';
import Header from '../../sharedComponents/header';
import ContentBlock from '../../sharedComponents/contentBlock';
import PrimaryButton from '../../sharedComponents/button';

import allyKoImg from '../../images/allyko.png';
import alisonkouImg from '../../images/alisonkou.png';
import { BLURB, CONTACT_BLURB } from '../../constants';

const About = () => {
    return (
        <>
            <Header page='about' title='about us'/>
            <ContentBlock title='Meet the Founders'>
                <Typography variant='paragraph' paragraph='true' sx={{ whiteSpace: 'pre-line'}}>
                    {BLURB}
                </Typography>
            </ContentBlock>
            <ContentBlock bgcolor='secondary.main' title='Connect With Us'>
                <Typography variant='introText' sx={{mb:3}}>{CONTACT_BLURB}</Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        },
                        gap: {sm: 10, xs: 5},
                        [`& .${imageListItemClasses.root}`]: {
                            display: 'flex',
                            flexDirection: 'column'
                        }
                    }}
                >
                    <ImageListItem sx={{alignItems:'center'}}>
                        <img src={`${allyKoImg}`} alt={allyKoImg}/>
                        <PrimaryButton url={`https://www.instagram.com/koallytea/`} text='Ally Ko'/>
                    </ImageListItem>
                    <ImageListItem sx={{alignItems:'center'}}>
                        <img src={`${alisonkouImg}`} alt={alisonkouImg}/>
                        <PrimaryButton url={`https://www.instagram.com/alisonhkou`} text='Alison Kou'/>
                    </ImageListItem>
                </Box>
            </ContentBlock>
        </>
    );
};

export default About;