import React from 'react';
import { Box, Typography } from '@mui/material';

import homeHeader from '../images/headers/homeHeader.png';
import aboutHeader from '../images/headers/aboutHeaderMobile.png';
import galleryHeader from '../images/headers/galleryHeader.png';
import productsHeader from '../images/headers/productsHeader.png';
import orderHeader from '../images/headers/orderHeader.png';

import homeHeaderMobile from '../images/headers/homeHeaderMobile.png';
import aboutHeaderMobile from '../images/headers/aboutHeaderMobile.png';
import galleryHeaderMobile from '../images/headers/galleryHeaderMobile.png';
import productsHeaderMobile from '../images/headers/productsHeaderMobile.png';
import orderHeaderMobile from '../images/headers/orderHeaderMobile.png';


const Header = (props) => {
    const headerHeight = (size) => {
        let height = 365;
        if (props.page === 'home' && size === 'xs') height = 500
        else if (props.page === 'home' && size === 'sm') height = 670
        else if ((props.page === 'orderAgreements' || props.page === 'products') && size === 'xs') height = 450;
        else if (size === 'xs') height = 200;
        
        return height;
    };
    let bgImage = '';
    let bgImageMobile = '';

    switch (props.page) {
        case 'home':
            bgImage = homeHeader;
            bgImageMobile = homeHeaderMobile;
            break;
        case 'about':
            bgImage = aboutHeader;
            bgImageMobile = aboutHeaderMobile;
            break;
        case 'gallery':
            bgImage = galleryHeader;
            bgImageMobile = galleryHeaderMobile;
            break;
        case 'products':
            bgImage = productsHeader;
            bgImageMobile = productsHeaderMobile;
            break;
        default:
            bgImage = orderHeader;
            bgImageMobile = orderHeaderMobile;
    };
          
    return (
        <Box sx={{
            display:'flex', 
            flexDirection:'column', 
            alignItems:'center', 
            justifyContent:'center', 
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: {
                sm: `url(${bgImage})`,
                xs: `url(${bgImageMobile})`
            },
            backgroundPosition: 'center',
            height: {
                sm: headerHeight('sm'),
                xs: headerHeight('xs')
            },
            p: {sm:0, xs:5}
        }}> 
            <Typography variant='h1'>{props.title}</Typography>
            <Typography align='center' variant='subtitle' sx={{mt:3, width: {xs:'100%', sm:'50%'}}}>{props.subtitle}</Typography>
            {props.children}
        </Box>
    );
};

export default Header;