import { 
  ADDRESS_ERR, 
  EMAIL_ERR, 
  NAME_ERR, 
  PRODUCT_MSG_ERR_EMPTY, 
  PRODUCT_DESC_ERR_EMPTY, 
  PRODUCTS_ERR,
  IMAGES_ERR } 
from "../../constants";

// Order Form
export const validateOrder = (order, setProductMsgErr, setProductDescErr, setProductSelectionErr, setNoImagesErr) => {
  const errors = [];
  const { productMsg, productDesc } = order;
  const { cardLandscape, cardPortrait, postcardLandscape, postcardPortrait } = order.products;

  setProductMsgErr(false);
  setProductDescErr(false);
  setProductSelectionErr(false);
  setNoImagesErr(false);

  if (productMsg === ''){
      errors.push({err: 'productMsg', msg: PRODUCT_MSG_ERR_EMPTY});
      setProductMsgErr(true);
  };
  if (productDesc === ''){
      errors.push({err: 'productDesc', msg: PRODUCT_DESC_ERR_EMPTY});
      setProductDescErr(true);
  };
  if (order.images?.length < 1){
    errors.push({err: 'images', msg: IMAGES_ERR});
    setNoImagesErr(true);
  };
  if (cardLandscape === 0 && cardPortrait === 0 && postcardLandscape === 0 && postcardPortrait === 0){
      errors.push({err: 'productSelection', msg: PRODUCTS_ERR});
      setProductSelectionErr(true);
  };  

  return errors;
};

// Review Order
const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
export const getOrderLetter = (index) => {
  return alphabet[index];
};

export const calculateEstimatedCost = (orders) => {
  let total = 0;
  orders.forEach(order => {
    const {cardLandscape, cardPortrait, postcardLandscape, postcardPortrait} = order.products;
    const totalNumProducts = cardLandscape + cardPortrait + postcardLandscape + postcardPortrait;
    const additionalProductsCost = (totalNumProducts - 1 > 0 ) ? 3 * (totalNumProducts - 1) : 0;
    total += 10 + additionalProductsCost + (order.digitalCopy === 'Yes' && 10);
  });
  return total;
};

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const validateContactForm = (clientInfo, setNameErr, setAddressErr, setEmailErr) => {
    const errors = [];
    const { name, address, email } = clientInfo;

    setNameErr(false);
    setAddressErr(false);
    setEmailErr(false);

    if (name === ''){
        errors.push({err: 'name', msg: NAME_ERR});
        setNameErr(true);
    }
    if (address === ''){
        errors.push({err: 'address', msg: ADDRESS_ERR});
        setAddressErr(true);
    }
    if (email === '' || !validateEmail(email)){
        errors.push({err: 'email', msg: EMAIL_ERR});
        setEmailErr(true);
    }
    
    return errors;
};