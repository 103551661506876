import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, ImageListItem, FormControlLabel, Radio, RadioGroup, TextField, Typography, inputBaseClasses, filledInputClasses, inputLabelClasses } from '@mui/material';
import { Add, Close, Remove } from '@mui/icons-material';
import { imageListItemClasses } from '@mui/material/ImageListItem';
import { styled } from '@mui/material/styles';

import { PRODUCTS, PRODUCT_MSG_SUBTITLE, PRODUCT_DESC_SUBTITLE, PRODUCT_SUBTITLE, PRODUCT_MSG_ERR_TITLE, PRODUCT_DESC_ERR_TITLE, CHOOSE_PRODUCT_ERR_TITLE, IMAGE_MSG_ERR_TITLE, productMsgPlaceholder, productDescriptionPlaceholder, MAX, MIN, spacing35 } from '../../constants';

export const StyledTextField = styled(TextField)({
// focused (single)
[`& .${filledInputClasses.root}.${filledInputClasses.focused} .${filledInputClasses.input}`]: {
    color: 'rgb(24, 54, 93)', //dark blue
    background: 'white',
    border: 'none',
},
// inactive
[`& .${filledInputClasses.input}`]: {
    background: 'rgb(254, 240, 236)',
    border: '3px solid rgb(254, 240, 236)', //pink
},
// focused (multiline) & periwinkle border.
[`& .${filledInputClasses.root}.${filledInputClasses.focused}`]: {
    color: 'rgb(24, 54, 93)', //dark blue
    background: 'white',
    border: '3px solid rgb(71,78,155)', //periwinkle
},
// label
[`& .${inputLabelClasses.filled}.${filledInputClasses.focused}`]: {
    color: 'rgb(24, 54, 93)',
},
// inactive multiline: white border
[`& .${inputBaseClasses.root}.${filledInputClasses.root}`]: {
    backgroundColor: 'rgb(254, 240, 236)',
},
// focused multiline: white border
[`& .${inputBaseClasses.root}.${filledInputClasses.focused}`]: {
    backgroundColor: 'white',
    transition: 'none'
}
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const gap = 50;
const mx = {sm: 15, xs: '0px'};

const OrderForm = ({order, setCurrentOrder, imgs, setImgs, productMsgErr, productDescErr, productSelectionErr, noImagesErr}) => {
    // Handle Changes
    const handleTextChange = (e) => {
        const {value, name} = e.currentTarget; //destructure
        setCurrentOrder((curr) => {return {...curr, [name]:value}})
    }

    const handleDigitalCopy = (e) => {
        const {value} = e.target; //destructure
        setCurrentOrder((curr) => {return {...curr, digitalCopy:value}})
    }
    
    const decreaseCount = (e) => {
        const {name} = e.currentTarget; //destructure
        // if (order[currentOrder].products[name] > MIN) {
        if (order.products[name] > MIN) {
            setCurrentOrder(currOrder => ({
                ...currOrder,
                products: {
                    ...currOrder.products,
                    [name]: currOrder.products[name]-1
                }
            }));
        }
    };

    const incrementCount = (e) => {
        const {name} = e.currentTarget; //destructure
        //if (order[currentOrder].products[name] < MAX) {
       if (order.products[name] < MAX) {
            setCurrentOrder(currOrder => ({
                ...currOrder,
                products: {
                    ...currOrder.products,
                    [name]: currOrder.products[name]+1
                }
            }));        
        }
    };

    // https://levelup.gitconnected.com/how-to-implement-multiple-file-uploads-in-react-4cdcaadd0f6e
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState(false);
    const MAX_COUNT = 10;

    useEffect(() => {
        setUploadedFiles([]); // only if first time we makesure it's empty.
        let uploaded = imgs;
        let limitExceeded = false;

        if (!isDeletingFile){
            uploaded = [...uploadedFiles];
            imgs.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    uploaded.push(file);
                    if (uploaded.length === MAX_COUNT) setFileLimit(true);
                    if (uploaded.length > MAX_COUNT) {
                        alert(`You can only add a maximum of ${MAX_COUNT} files`);
                        setFileLimit(false);
                        limitExceeded = true;
                        return true;
                    };
                };
            });
        }
        else {
            setIsDeletingFile(false);
        }
        
        if (!limitExceeded) {
            setUploadedFiles(uploaded);
            setCurrentOrder((curr) => {return {...curr, images:imgs}});
        }
    }, [imgs]);

    const handleFileEvent =  (e) => {
        const images =[];
        for (var i = 0; i < e.target.files.length; i++) {
            images.push(e.target.files[i]);
        }
        setImgs(prevImgs => [...prevImgs, ...images]);
        // trigger useEffect to update order state and rerender img titles.
    };

    const handleDeleteFile = (file) => {
        const newSetOfImgs = imgs.filter(img => {
            return img.name !== file;
        });
        setIsDeletingFile(true);
        setImgs(newSetOfImgs);
        // trigger useEffect to update order state and rerender img titles.
    };
    
    return (
        <Box component='form' sx={{display: 'flex', width:'100%', flexDirection:'column', bgcolor:'white', py:5}}>
            <Box sx={{display: 'flex', flexDirection:'column', mx:mx}}>
                <Typography align='center' variant='title' color={productMsgErr && 'primary.error'}>Write your product message</Typography>
                <Typography align='center' variant='subtitle' color={productMsgErr && 'primary.error'} sx={{mb:2}}>{productMsgErr ? PRODUCT_MSG_ERR_TITLE : PRODUCT_MSG_SUBTITLE}</Typography>
                <StyledTextField
                    label='Product Message'
                    placeholder={productMsgPlaceholder}
                    name='productMsg'
                    onChange={handleTextChange}
                    value={order.productMsg}
                    variant='filled'
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ shrink: true }}
                    minRows={2}
                    multiline
                />
            </Box>

            <Box sx={{display: 'flex', flexDirection:'column', mx:mx, my:3}}>
                <Typography align='center' variant='title' color={productDescErr && 'primary.error'}>Describe your product illustration</Typography>
                <Typography align='center' variant='subtitle' color={productDescErr && 'primary.error'} sx={{mb:2}}>{productDescErr ? PRODUCT_DESC_ERR_TITLE : PRODUCT_DESC_SUBTITLE}</Typography>
                <StyledTextField 
                    label='Product Description'
                    placeholder={productDescriptionPlaceholder}
                    minRows={10}
                    multiline
                    name='productDesc'
                    onChange={handleTextChange}
                    value={order.productDesc}
                    variant='filled'
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{ shrink: true }}
                    sx={{backgroundColor: 'rgb(254, 240, 236)'}}
                />
            </Box>

            <Box sx={{display: 'flex', flexDirection:'column', alignItems:'center', mx:mx}}>
                {noImagesErr &&
                    <Typography align='center' variant='subtitle' color='primary.error' sx={{mb:2}}>{IMAGE_MSG_ERR_TITLE}</Typography>
                }
                <Button component='label' sx={{
                    bgcolor:'blue.periwinkle', 
                    color:'white',
                    width: '250px',
                    height: '60px',
                    fontWeight: 700,
                    fontSize: 16,
                    ':hover': {
                        bgcolor: 'blue.light',
                        color: 'blue.periwinkle'
                    },
                    zIndex: 100,
                }}> 
                    + Upload Files
                    <VisuallyHiddenInput accept='image/*' multiple type="file" onChange={handleFileEvent}/>
                </Button>
                {
                    order?.images?.length ? 
                    <Box display='flex' flexDirection='column' className="uploaded-files-list" backgroundColor='secondary.main' width='100%' py={spacing35} gap={2} mt={3}>
                        {order?.images?.map(file => (
                            <Box display='flex' justifyContent='space-between' maxWidth='100%' mx={spacing35}>
                                <Typography 
                                    variant='imgTitle' 
                                    overflow='hidden' 
                                    whiteSpace='nowrap' 
                                    textOverflow='ellipsis' 
                                    sx={{borderBottom:'1px solid #18365D', width:'calc(100% - 35px)'}}
                                >
                                    {file.name}
                                </Typography>
                                <IconButton onClick={()=>handleDeleteFile(file.name)} 
                                    sx={{
                                        p:0, 
                                        backgroundColor:'blue.periwinkle', 
                                        borderRadius: '10%', 
                                        '&:hover': {backgroundColor: 'blue.periwinkle'},
                                        width: '25px',
                                        height: '25px'
                                    }}
                                >
                                    <Close sx={{color: 'white', size:3}}/>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    : ''
                }
            </Box>

            <Box sx={{display: 'flex', flexDirection:'column', mx:mx, mt:3}}>
                <Typography align='center' variant='title' color={productSelectionErr && 'primary.error'}>Choose your product</Typography>
                <Typography align='center' variant='subtitle' color={productSelectionErr && 'primary.error'} sx={{mb:2}}>{productSelectionErr ? CHOOSE_PRODUCT_ERR_TITLE : PRODUCT_SUBTITLE}</Typography>
                <Box
                sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                },
                gap:{gap},
                [`& .${imageListItemClasses.root}`]: {
                    display: 'flex',
                    flexDirection: 'column'
                }
                }}
                >
                    {PRODUCTS.map((item, idx) => (
                    <div key={`key_${idx}`}>
                        <ImageListItem key={item.img} sx={{alignItems:'center'}}>
                            <img src={`${item.img}`} alt={item.title} loading='lazy'/>
                            <Typography variant='title' textAlign='center' sx={{mt:2}}>{item.title}</Typography>
                            <Typography variant='title'>{item.size}</Typography>
                            <Box>
                                <IconButton aria-label='decrease' name={item.name} onClick={decreaseCount}>
                                    <Remove/>
                                </IconButton>
                                <Typography variant='introText'>{order.products[item.name]}</Typography>
                                <IconButton aria-label='increase' name={item.name} onClick={incrementCount}>
                                    <Add/>
                                </IconButton>
                            </Box>
                        </ImageListItem>
                    </div>
                    ))}
                </Box>
            </Box>

            <Box sx={{display: 'flex', flexDirection:'row', mx:mx, justifyContent:'space-between', my: 3}}>
                <Typography variant='title'>Do you want a digital copy?:</Typography>
                <RadioGroup row onChange={handleDigitalCopy}>
                    <FormControlLabel 
                        value='Yes' 
                        label='Yes (+$10)' 
                        key='Yes'
                        control={<Radio  sx={{color:'secondary.dark', '&.Mui-checked': {color: 'secondary.dark'}}}/>}
                        checked={order.digitalCopy === 'Yes'}
                    />
                    <FormControlLabel 
                        value='No' 
                        label='No' 
                        key='No'
                        control={<Radio  sx={{color:'secondary.dark', '&.Mui-checked': {color: 'secondary.dark'}}}/>} 
                        checked={order.digitalCopy === 'No'}
                    />                        
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default OrderForm;
