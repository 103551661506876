import React from 'react';
import { AppBar, Box, Button, Grid, Menu, MenuItem, Toolbar } from '@mui/material';
import { Instagram, MailOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/logo.png';

const NavBar = () => {
    
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const pages = ['About', 'Gallery', 'Products', 'Order'];

    return (
        <AppBar position='static'>
          <Grid item container> 
              <Grid item xs={1} md={2}></Grid>
              <Grid item xs={10} md={8}>
                <Toolbar disableGutters sx={{justifyContent:'space-between'}}>
                    {/* Logo and Title For Larger Viewports | Desktop*/}
                    <Box sx={{display: { xs: 'none', md: 'flex' }}} >
                      <a href='/'><img src={logo} alt='logo' width='150'/></a>
                    </Box>

                     {/* Logo and Title For Smaller Viewports | Phone */}
                     <Box sx={{display: { xs: 'flex', md: 'none' }}} >
                      <a href='/'><img src={logo} alt='logo' width='150'/></a>
                    </Box>
                    
                    {/* Hamburger Nav */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                      <IconButton onClick={handleOpenNavMenu} sx={{color:'blue.dark'}}>
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        id='menu-appbar'
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                          display: { xs: 'block', md: 'none' }
                        }}
                      >
                        {pages.map((page) => (
                          <MenuItem key={page} onClick={handleCloseNavMenu}>
                            {/* <Typography textAlign='center' >{page}</Typography> */}
                            {/* link page */}
                            <Button
                              key={page}
                              onClick={handleCloseNavMenu}
                              sx={{ my: 0, color:'blue.dark', display: 'block' }}
                              href={`./${page.toLowerCase()}`}
                            >
                          {page}
                        </Button>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
        
                    {/* Pages and Social Media*/}
                    <Box alignItems='center' sx={{ display: { xs: 'none', md: 'flex' }, justifySelf: 'end' }}>
                      {pages.map((page) => (
                        <Button
                          key={page}
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, color:'blue.dark', display: 'block' }}
                          href={`./${page.toLowerCase()}`}
                        >
                          {page}
                        </Button>
                      ))}
                      {/* Social Media Icons */}
                      <Box sx={{ display: { xs: 'none', md: 'flex' }}}>
                        <IconButton color='secondary' href='https://www.instagram.com/dispatchko/'>
                          <Instagram sx={{color:'secondary.dark'}}/>                
                        </IconButton>
                        <IconButton color='secondary' href='mailto: hellodispatchko@gmail.com'>
                          <MailOutline sx={{color:'secondary.dark'}}/>
                        </IconButton>
                      </Box>
                    </Box>
                  </Toolbar>
              </Grid>
              <Grid item xs={1} md={2}></Grid>
          </Grid>
        </AppBar>
      );
};

export default NavBar;






