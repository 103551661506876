import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';


// TODO: Replace with final config and add to ENV file.
const firebaseConfig = {
    apiKey: "AIzaSyDPVzGMLwPtv8CjrLPxrDCffVgs3sG6e4s",
    authDomain: "dispatch-ko.firebaseapp.com",
    databaseURL: "https://dispatch-ko-default-rtdb.firebaseio.com",
    projectId: "dispatch-ko",
    storageBucket: "dispatch-ko.appspot.com",
    messagingSenderId: "925210205243",
    appId: "1:925210205243:web:96b8c7c363e9db51cc901e",
    measurementId: "G-5KM7ZTFYDL"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getDatabase(app);