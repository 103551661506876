import React from 'react';
import Header from '../../sharedComponents/header';
import ImageGallery from './imageGallery';

const Gallery = () => {
    return (
        <>
            <Header page='gallery' title='our gallery'/>
            <ImageGallery/> 
        </>
    );
};

export default Gallery;