import React from 'react';
import { Button } from '@mui/material';
import { spacing35 } from '../constants';

export const PrimaryButton = (props) => {
    return(
        <Button 
            variant='contained' 
            href={props.url} 
            sx={{
                bgcolor:'blue.periwinkle', 
                color:'white',
                width: '250px',
                height: '60px',
                fontWeight: 700,
                fontSize: 16,
                mt: props.mt || spacing35,
                mb: props.mb || 0,
                mr: props.mr !== undefined ? props.mr : 0,
                ':hover': {
                    bgcolor: 'blue.light',
                    color: 'blue.periwinkle'
                },
                zIndex: 100
            }}
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    );
};

export const SecondaryButton = (props) => {
    return (
        <Button 
            variant='contained' 
            href={props.url} 
            sx={{
                bgcolor:'blue.periwinkle', 
                color:'white',
                width: '200px',
                height: '45px',
                fontWeight: 700,
                fontSize: 16,
                mt: props.mt || 2,
                mr: props.mr || 0,
                ':hover': {
                    bgcolor: 'secondary.light',
                    color: 'white'
                }
            }}
            onClick={props.onClick}
        >
            {props.text}
        </Button> 
    )
    
}

export default PrimaryButton;