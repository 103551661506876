import axios from 'axios';
import { maxRecords } from '../constants';

export const getAllImages = async () => {
    const images = [];
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.airtable.com/v0/appLXLVt47tyIGZHw/ImageGallery?maxRecords=${maxRecords}&sort%5B0%5D%5Bfield%5D=Date%20Added&sort%5B0%5D%5Bdirection%5D=desc`,
        headers: { 
            'Authorization': `Bearer patNeS2wusnwJG4vV.28d95c8af589997af02579490ba5ef748698c1216635b3569a52f815d607dc16`, 
        },
    };

    const { data } = await axios.request(config);
    data?.records?.forEach(record => {
        console.log('record::',record?.fields['Title']);
        images.push({
            title: record?.fields?.title,
            imageUrl: record?.fields['Image'][0].url,
        });
    });

    return images;
};

// export const getLatestImages = async () => {
//     const data = [];
//     const records = await imageGallery.select({
//         maxRecords: 3,
//         sort: [{field: 'Date Added', direction: 'desc'}]
//     }).firstPage();

//     records.forEach(record => {
//         data.push({
//             title: record.get('Title'),
//             imageUrl: record.get('Image')[0].url,
//         });
//     });
//     return data;
// };

// getAllImages();
// getLatestImages();getLatestImages1
// nodemon path