import React, {useState} from 'react';
import { Box, IconButton, ImageListItem, Modal, Stack, Typography } from '@mui/material';
import { imageListItemClasses } from '@mui/material/ImageListItem';
import { Close } from '@mui/icons-material';
import { East, South } from '@mui/icons-material';

import ContentBlock from '../../../sharedComponents/contentBlock';
import { VIEW_EXAMPLES } from '../../../constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'85vw', md:'75vw'},
    height: 'auto',
    maxHeight: {xs: '85vh', md:'80vh'},
    p: {xs:'20px', md:'30px'},
    backgroundColor: 'blue.light'
};

const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap:'20px',
    height: '100%'
};

  const imageModalStyle = { 
    maxHeight: '100%', 
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
}

const examplesStyle = { 
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    flexGrow: 0,
    display: 'block'
}

const ViewExamples = () => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('false');

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleImage = (item) => {
        setImage(item);
        setOpen(true);
    };

    return(
        <ContentBlock title='View Our Example Process'>
            <Box
                sx={{
                display: 'flex',
                flexDirection: {xs:'column', md:'row'},
                alignItems:'baseline',
                gap: '50px',
                [`& .${imageListItemClasses.root}`]: {
                    display: 'flex',
                    flexDirection: 'column'
                }
                }}
            >
                {VIEW_EXAMPLES.map((item) => (
                    <ImageListItem key={item.img} sx={{alignItems:'center'}} >
                        <img 
                            src={`${item.img}`} 
                            alt={item.title} 
                            onClick={(e) => handleImage(item)}
                            style={examplesStyle}
                        />
                        <Typography variant='title' align='center' sx={{color:'black'}}>{item.title}</Typography>
                    </ImageListItem>
                ))}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                sx={{backgroundColor: 'blue.periwinkle'}}
            >
                <Box sx={style}>
                    <IconButton onClick={handleClose} 
                        sx={{p:0, position:'absolute', top:10, right:10, backgroundColor:'blue.periwinkle', borderRadius: '10%', '&:hover': {
                            backgroundColor: 'blue.periwinkle'
                          }}}
                    >
                        <Close sx={{color: 'white'}}/>
                    </IconButton>

                    <Box sx={boxStyle}>
                        <Typography variant='h3' textAlign='center'>{image.title}</Typography>
                        <Typography variant='introText'>{image.description}</Typography>
                        
                        {/* desktop */}
                        <Stack 
                            display={{xs:'none', md:'flex'}}
                            direction='row'
                            alignItems='center' 
                            justifyContent='center'
                            height='100%'
                        > 
                            <Box height='100%' sx={{display:'flex', alignItems:'center'}}>
                                <img
                                    src={image.originalImg}
                                    alt={image.originalImg}
                                    style={imageModalStyle}
                                />
                            </Box>
                            <East sx={{color: 'blue.dark', fontSize: 70, mx:'5px', display: {xs:'none', md:'block'}}}/>
                            <South sx={{color: 'blue.dark', fontSize: 40, my:'5px', display: {xs:'block', md:'none'}}}/>
                            <Box height='100%' sx={{display:'flex', alignItems:'center'}}>
                                <img
                                    src={image.img}
                                    alt={image.img}
                                    style={imageModalStyle}
                                />
                            </Box>
                        </Stack>
                        
                        {/* mobile */}
                         <Box display={{xs:'flex', md:'none'}} width='auto' height='100%'>
                            <Box 
                                component='img' 
                                src={image.example} 
                                sx={{ width:'100%', height:'100%', maxHeight:'375px'}} 
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </ContentBlock>
    );
};

export default ViewExamples;