import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, ImageListItem, Typography } from '@mui/material';
import { imageListItemClasses } from '@mui/material/ImageListItem';

import pinkBG from '../../images/pinkBG.png';

import Header from '../../sharedComponents/header';
import ContentBlock from '../../sharedComponents/contentBlock';
import PrimaryButton from '../../sharedComponents/button';
import ProcessStep from './components/processStep';
import ViewExamples from './components/viewExamples';
import cardExampleImg from '../../images/cardExample.png';
import postcardExampleImg from '../../images/postcardExample.png';
import { FAQ, PROCESS, PRODUCTS_BLURB, spacing30 } from '../../constants';

const gap = 50;
const marginSide = 5;
const paddingTop = 3;
const ourProducts = [
    {
        img: cardExampleImg,
        title: 'Card (5.5 x 4.25”)'
    },
    {
        img: postcardExampleImg,
        title: 'Postcard (5.5 x 4.25”)'
    },
];

const Products = () => {
    return (
        <>
        <Header page='products' title='products' subtitle={PRODUCTS_BLURB}/>
        <ContentBlock>
            <Box
                sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                },
                gap: {gap},
                [`& .${imageListItemClasses.root}`]: {
                    display: 'flex',
                    flexDirection: 'column'
                }
                }}
            >
                {ourProducts.map((item) => (
                    <ImageListItem key={item.img}sx={{alignItems:'center'}} > 
                        <img src={`${item.img}`} alt={item.title} loading='lazy'/>
                        <Typography variant='title' sx={{mt:3}}>{item.title}</Typography>
                    </ImageListItem>
                ))}
            </Box>
        </ContentBlock>
        
        <Box sx={{backgroundImage:{xs: 'none', md:`url(${pinkBG})`}, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', bgcolor:'secondary.main', }}>
            <ContentBlock title='Our Process' bgColor={'transparent'} height='100%'>
                <Box position='relative' height='100%' display='flex' flexDirection='column' alignItems='center'>
                    <Box sx={{position:'absolute', width:spacing30, height:spacing30, borderRadius:'50%', backgroundColor:'blue.periwinkle', zIndex: 1}}/>
                    <Box sx={{position: 'absolute', width:'5px', height:'100%', backgroundColor:'blue.periwinkle', zIndex: 1}} />
                    {PROCESS.map((step, index) => (
                        <ProcessStep width={600} margin={index === 0 ? 7 : 5} step={step} index={index+1}/>
                    ))}
                    <PrimaryButton text='Order Now' url='./order'/>
                </Box>
            </ContentBlock>
            <ViewExamples />
        </Box>
        
        <ContentBlock title='FAQ'>
            <Box>
                {FAQ.map(item => {
                    return (
                        <Accordion disableGutters sx={{mt:2}}>
                            <AccordionSummary sx={{bgcolor: 'blue.periwinkle', height:'100%', '& .MuiAccordionSummary-content': {justifyContent: 'center'}}}> 
                                <Typography variant='subtitle' sx={{color: 'primary.main', mx:marginSide}}>{item.question}  </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{bgcolor: 'blue.light', pt:paddingTop}}>
                                <Typography variant='subtitle' paragraph='false' sx={{mx:marginSide}}>{item.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </ContentBlock>
        </>
    );
};

export default Products;

