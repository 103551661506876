import React from 'react';
import { Box, ImageListItem, Typography } from '@mui/material';
import { imageListItemClasses } from '@mui/material/ImageListItem';

import Header from '../../sharedComponents/header';
import ContentBlock from '../../sharedComponents/contentBlock';
import PrimaryButton from '../../sharedComponents/button';

import { CARD_DESIGN_PROCESS, HOME_HERO, HOME_INTRO, LATEST_IMGS } from '../../constants';

const Home = () => {
    return (
        <>
            <Header page='home' title='send with purpose' subtitle={HOME_HERO} sx={{mt:'-50px'}}/>
            <ContentBlock bgcolor={'blue.periwinkle'}>
                <Typography variant='introText' color='white'>{HOME_INTRO}</Typography>
            </ContentBlock>

            <ContentBlock title='What We Offer'>
                <Box
                sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                },
                gap:5,
                [`& .${imageListItemClasses.root}`]: {
                    display: 'flex',
                    flexDirection: 'column'
                }
                }}
                >
                    {CARD_DESIGN_PROCESS.map((item) => (
                        <ImageListItem key={item.img} sx={{alignItems:'center'}}>
                            <img src={`${item.img}`} alt={item.title} loading='lazy' width={'100%'}/>
                            <Typography variant='introText' sx={{mt:2}}>{item.title}</Typography>
                            <Typography variant='paragraph' align='center'>{item.subtitle}</Typography>
                        </ImageListItem>
                    ))}
                </Box>
                <PrimaryButton url={`./order`} text='Order a Card'/>
            </ContentBlock>

            <ContentBlock bgcolor='secondary.main' title='Our Latest Designs'>
                <Box
                    sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                    },
                    gap:5,
                    [`& .${imageListItemClasses.root}`]: {
                        display: 'flex',
                        flexDirection: 'column'
                    }
                    }}
                >
                    {LATEST_IMGS.map((item, idx) => (
                        <ImageListItem key={`latestImg_${idx}`} >
                            <img src={`${item}`} alt={`latestImg_${idx}`} loading='lazy'/>
                        </ImageListItem>
                    ))}
                </Box>
                <PrimaryButton url={`./gallery`} text='View Gallery' />
            </ContentBlock>
        </>
    );
};

export default Home;