import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const lineHeight = '18px';
const Bullet = styled('div')({
    borderRadius: '50%',
    minWidth: `${lineHeight}`,
    maxWidth: `${lineHeight}`,
    height: `${lineHeight}`, // must be same as height
    padding: 8,
    border: '5px solid #E89F90',
    color: '#18365D',
    textAlign: 'center',
});

const NumberedListIcon = (props) => {
    return (
        <Bullet>
            <Typography variant='subtitle' sx={{ lineHeight: lineHeight, color:'secondary.contrastText' }}>{props.step}</Typography>
        </Bullet>
    );
};

export default NumberedListIcon;