import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../../sharedComponents/header';
import ContentBlock from '../../sharedComponents/contentBlock';
import PrimaryButton from '../../sharedComponents/button';

import OrderForm from './orderForm';
import OrderReview from './orderReview';
import OrderAgreements from './components/agreements';

import { emptyClientInfo, emptyOrder, AGREEMENTS_BLURB, SUBMIT_BLURB } from '../../constants';
import { OrderError, SubmitOrderError } from './components/errors';
import { validateContactForm, validateOrder } from './utils';

import { database, storage } from '../../firebase';
import { ref, push, child, update} from 'firebase/database';
import { ref as sRef, uploadBytesResumable, getDownloadURL} from 'firebase/storage';
import { SignalCellularNullSharp } from '@mui/icons-material';

const mx = '75px';

const OrderTest = () => {
    const [orders, setOrders] = useState([]);
    const [currentIdx, setCurrentIdx] = useState(100);
    const [currentOrder, setCurrentOrder] = useState(emptyOrder);
    const [isEditingOrder, setIsEditingOrder] = useState(null);
    const [page, setPage] = useState(0);

    // Order States
    const [isError, setError] = useState(false);
    const [formErrors, setformErrors] = useState([]);
    const [productMsgErr, setProductMsgErr] = useState(false);
    const [productDescErr, setProductDescErr] = useState(false);
    const [productSelectionErr, setProductSelectionErr] = useState(false);
    const [noImagesErr, setNoImagesErr] = useState(false);

    // Client Info States
    const [clientInfo, setClientInfo] = useState(emptyClientInfo);
    const [isClientError, setClientError] = useState(false);
    const [clientErrors, setClientErrors] = useState([]);
    const [nameErr, setNameErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);

    // Submitting to DB
    const [imgs, setImgs] = useState([]);

    // for scrolling position
    const topOfContentRef = useRef();

    const onClickOrderAnotherDesign = (e) => {
        let errors = validateOrder(currentOrder, setProductMsgErr, setProductDescErr, setProductSelectionErr, setNoImagesErr);
        setformErrors(errors);

        if (!errors.length){ 
            // turn this into function
            if (isEditingOrder){
                const foundOrder = orders.findIndex(order => order.id === isEditingOrder);
                const updatedOrders = orders;
                updatedOrders[foundOrder] = currentOrder;
                setOrders(updatedOrders);

                setIsEditingOrder(null);
                setCurrentOrder(emptyOrder); 
            }
            else {
                setOrders(allOrders => [...allOrders, {...currentOrder, id: currentIdx}]);
            }
            setCurrentIdx(currentIdx+1);
            setCurrentOrder(emptyOrder);
            setImgs([]);
            topOfContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            setError(true);
        }
    };

    const onClickReview = (e) => {    
        let errors = validateOrder(currentOrder, setProductMsgErr, setProductDescErr, setProductSelectionErr, setNoImagesErr);
        setformErrors(errors);

        if (!errors.length){ 
            if (isEditingOrder){
                const foundOrder = orders.findIndex(order => order.id === isEditingOrder);
                const updatedOrders = orders;
                updatedOrders[foundOrder] = currentOrder;
                setOrders(updatedOrders);

                setIsEditingOrder(null);
                setCurrentOrder(emptyOrder); 
            }
            else {
                setOrders(allOrders => [...allOrders, {...currentOrder, id: currentIdx}]);
                setCurrentIdx(currentIdx+1);
                setCurrentOrder(emptyOrder);
            }
            setPage(2);
            topOfContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            setError(true);
        }
    }

    const onClickCreateNewOrder = () => {
        setImgs([]);
        setOrders([]);
        setPage(1);
    };

    const getImgURL = (file, orderFolderName, index ) => {
        const storageRef = sRef(storage, `/${orderFolderName}/order${index+1}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // const percent = Math.round(
                //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // );
 
                // // update progress
                // setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log('downloadURL::',downloadURL);
                  });
            }
        );
    };

    const submitToDB = () => {
        // Create DB Key
        const date = new Date();
        // const months = ['Jan','Feb','March','April','May','June','July','Aug','Sept','Oct','Nov','Dec'];
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let time = Date.now(); // unique

        let firstName = clientInfo.name?.split(' ')[0];
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

        const orderFolderName = `${firstName}-${month}-${day}-${year}-${time}`;
        
        // Create DB object
        // https://www.section.io/engineering-education/registration-form-react.js-firebase/
        let fullOrder = {
            contact: {
                name: clientInfo.name,
                address: clientInfo.address,
                email: clientInfo.email
            }
        };

        const privateUrl = `dispatch-ko`;
        
        orders.forEach((order, index) => {            
            const {images, products, ...filteredOrder} = order;
            // Setup for Images
            images?.forEach((file) => {
                getImgURL(file, orderFolderName, index);
            });

            // only create url if images were submitted.
            const dbFolderUrl = images ? `https://console.firebase.google.com/u/2/project/${privateUrl}/storage/${privateUrl}.appspot.com/files/~2F${orderFolderName}~2Forder${index+1}` : SignalCellularNullSharp;
            fullOrder.orders = {
                ...fullOrder.orders,
                [`order${index+1}`]: {
                    ...filteredOrder,
                    images: dbFolderUrl,
                    products
                }
            };
        });

        // Post to DB
        // const newPostKey = push(child(ref(database), 'orders')).key; //unique key
        const updates = {};
        updates[`/${firstName}-${month}-${day}-${year}-${time}`] = fullOrder;
        return update(ref(database), updates);
    };

    const onClickSubmit = (e) => {
        let errors = validateContactForm(clientInfo, setNameErr, setAddressErr, setEmailErr);
        setClientErrors(errors);

        if (!errors.length){ 
            submitToDB();
            setClientInfo(emptyClientInfo);
            setPage(3);
        }
        else {
            setClientError(true);
        };
    };

    const pages = {
        0: <OrderAgreements setPage={setPage} topOfContentRef={topOfContentRef}/>,
        1: // Order Form
            <Box ref={topOfContentRef}>
                <ContentBlock bgcolor={{xs:'white', md:'secondary.main'}} id='orderForm'>
                    { isError && <OrderError isError={isError} setError={setError} errors={formErrors}/>}
                    <OrderForm 
                        order={currentOrder} 
                        setCurrentOrder={setCurrentOrder}
                        imgs={imgs}
                        setImgs={setImgs}
                        productMsgErr={productMsgErr} 
                        productDescErr={productDescErr} 
                        productSelectionErr={productSelectionErr}
                        noImagesErr={noImagesErr}
                    />
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: 2, mx:mx, my: 3}}>
                        <PrimaryButton text='Order another design' onClick={onClickOrderAnotherDesign}/>
                        <PrimaryButton text='Review Order' onClick={onClickReview}/>
                    </Box>
                </ContentBlock>
            </Box>,
        2: // Review
            <Box>
                {isClientError && <SubmitOrderError isClientError={isClientError} setClientError={setClientError} clientErrors={clientErrors}/>}
                <OrderReview orders={orders} setOrders={setOrders} setCurrentOrder={setCurrentOrder} setPage={setPage} clientInfo={clientInfo} setClientInfo={setClientInfo} setIsEditingOrder={setIsEditingOrder} onClickSubmit={onClickSubmit} nameErr={nameErr} addressErr={addressErr} emailErr={emailErr}/> 
            </Box>,
        3: // Submitted
            <ContentBlock bgcolor={{xs:'white', sm:'secondary.main'}}>
                <Box sx={{display: 'flex', width:'100%', flexDirection:'column', alignItems:'center', bgcolor:'white', py:5}}>
                    <Typography variant='title' textAlign='center' sx={{mx:mx}}>Your order has been submitted.</Typography>
                    <Typography variant='subtitle' sx={{my: 3, mx:mx}}>{SUBMIT_BLURB}</Typography>
                    <PrimaryButton text='Order another design' onClick={onClickCreateNewOrder}/>
                </Box>
            </ContentBlock>
    };

    return (
        <>
            <Header ref={topOfContentRef} page={page === 0 ? 'orderAgreements' : 'orderForm'} title='order' subtitle={page === 0 && AGREEMENTS_BLURB}/>
            { pages[page] }
        </>
    );
};

export default OrderTest;