import React from 'react';
import { Box, Typography } from '@mui/material';
import ContentBlock from '../../../sharedComponents/contentBlock';
import ProcessStep from '../../products/components/processStep';
import PrimaryButton from '../../../sharedComponents/button';
import { AGREEMENTS, spacing35 } from '../../../constants';

const OrderAgreements = ({setPage, topOfContentRef}) => {
    const handleOnClick = (e) => {
        console.log('next page')
        setPage(1);
        // topOfContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        window.scrollTo(0, 0);
    };

    return (
        <ContentBlock bgcolor={{xs: 'white', md: 'secondary.main'}}>                
            <Box sx={{display: 'flex', width:'100%', flexDirection:'column', alignItems:'center', bgcolor:'white', py:5}}>
                <Typography variant='h3' sx={{mb:spacing35}}>Our Agreements</Typography>
                {AGREEMENTS.map((item, idx) => (
                    <ProcessStep key={`step_${idx+1}`} index={idx+1} step={item} width={'80%'}/>
                ))}
            </Box>
            <PrimaryButton onClick={handleOnClick} text='I agree'/>
        </ContentBlock>    
    );
};

export default OrderAgreements;