import React, { useState} from 'react';  
import { Box, Typography } from '@mui/material';

import { SecondaryButton, PrimaryButton } from '../../sharedComponents/button';
import ContentBlock from '../../sharedComponents/contentBlock';
import { contactTemplate, contactPlaceholder, ordersTemplate } from '../../constants';
import { calculateEstimatedCost, getOrderLetter } from './utils';

import ProductsField from './components/productField';
import { RemoveOrderError } from './components/errors';
import { StyledTextField } from './orderForm';


const OrderReview = ({orders, setOrders, setCurrentOrder, setPage, setIsEditingOrder, clientInfo, setClientInfo, onClickSubmit, nameErr, addressErr, emailErr}) => {
    const [isRemovingLastOrder, SetIsRemovingLastOrder] = useState(false);

    // Handle Changes
    const onClickRemoveOrder = (currOrder) => {
        if (orders.length === 1){
            SetIsRemovingLastOrder(true);
        }
        else {
            const newSetOfOrders = orders.filter(order => {
                return order !== currOrder;
            });
            setOrders(newSetOfOrders);
        };
    };

    const onClickEditOrder = (currOrder) => {
        setCurrentOrder(currOrder); 
        setIsEditingOrder(currOrder.id);
        setPage(1);
    };

    const handleTextChange = (e) => {
        const {value, name} = e.currentTarget; //destructure
        setClientInfo((curr) => {return {...curr, [name]:value}})
    };

    return (
        <>
            { isRemovingLastOrder && <RemoveOrderError isRemovingLastOrder={isRemovingLastOrder} SetIsRemovingLastOrder={SetIsRemovingLastOrder}/>}
            
            {/* desktop */}
            <Box display={{ xs: 'none', md: 'grid' }} backgroundColor='red'> 
                <ContentBlock bgcolor={{xs:'white', md:'secondary.main'}}>
                    <Box display='flex' flexDirection='column' width='100%'>
                    {
                        orders.map((order, idx) => {
                            return (
                                <Box component='form' key={`order_${idx+1}`} 
                                    sx={{display: 'flex', width:'100%', flexDirection:'column', bgcolor:'white'}}
                                >
                                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', bgcolor: 'blue.dark', height:'70px', px:5}}>
                                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>ORDER {getOrderLetter(idx)}</Typography>
                                        <Box>
                                            <SecondaryButton text='Edit Order' mt={'0px'} mr={3} onClick={()=>onClickEditOrder(order)} />
                                            <SecondaryButton text='Remove Order' mt={'0px'} onClick={() => onClickRemoveOrder(order)} />
                                        </Box>
                                    </Box>
                
                                    {/* Desktop Review */}
                                    <Box 
                                        display='grid'
                                        gridTemplateColumns='[labels] auto [controls] 1fr' 
                                        gap={3} gridAutoFlow='row' 
                                        sx={{p:5}}
                                    >
                                    {
                                        ordersTemplate.map((x) => {
                                            return <React.Fragment key={`product_${x.field}`}>
                                                <Typography gridColumn='labels' gridRow='auto' variant='subtitle' paragraph='false' sx={{mb:0}}>{x.title}</Typography>
                                                {
                                                    x.field === 'products' ? 
                                                        <ProductsField products={order.products}/>
                                                    :
                                                        <Typography gridColumn='controls' gridRow='auto' variant='primary' paragraph='false' sx={{mb:0, borderBottom:'1px solid #18365D'}}>
                                                            {order[x.field]} 
                                                        </Typography>
                                                }
                                            </React.Fragment>
                                        })
                                    }
                                    </Box>
                                </Box>
                            );
                        })
                    }
                    <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-between', bgcolor: 'blue.dark', p:5}}>
                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>ESTIMATED PRICE:</Typography>
                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>${calculateEstimatedCost(orders)}</Typography>
                </Box>
                    </Box>
                </ContentBlock>
            </Box>
            
            {/* Mobile Review */}
            <Box display={{ xs: 'flex', md: 'none' }} flexDirection='column'>
            {orders.map((order, idx) => {
            return (
                <Box  backgroundColor='secondary.main' key={`order_${idx+1}`} sx={{width:'100%'}} >
                    <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-between', bgcolor: 'blue.dark', p:5}}>
                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>ORDER {getOrderLetter(idx)}</Typography>
                        <Box sx={{display:'flex', flexDirection: 'column'}}>
                            <SecondaryButton text='Edit Order' onClick={()=>onClickEditOrder(order)} />
                            <SecondaryButton text='Remove Order' onClick={() => onClickRemoveOrder(order)} />
                        </Box>
                    </Box>
                    <ContentBlock bgcolor={{xs:'white', md:'secondary.main'}} id={`order_${idx}`}>
                    <Box display='flex' flexDirection='column' gap='30px' sx={{width:'100%', pb:'35px'}}>
                    {
                        ordersTemplate.map((x) => {
                            return <Box key={`product_${x.field}`}>
                                <Typography variant='subtitle' paragraph='false' sx={{mb:0}}>{x.title}</Typography>
                                {
                                    x.field === 'products' ? 
                                        <ProductsField products={order.products}/>
                                    :
                                        <Typography variant='primary' paragraph='false' sx={{mb:0, borderBottom:'1px solid #18365D'}}>
                                            {order[x.field]} 
                                        </Typography>
                                }
                            </Box>
                        })
                    }
                    </Box>
                    </ContentBlock>
                </Box>

            )
            })}
                <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-between', bgcolor: 'blue.dark', p:5}}>
                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>ESTIMATED PRICE:</Typography>
                        <Typography variant='subtitle' sx={{color: 'primary.main'}}>${calculateEstimatedCost(orders)}</Typography>
                </Box>
            </Box>

            {/* Contact Form */}
            <ContentBlock bgcolor={{xs:'white', md:'secondary.main'}} id='contact_form' pt={0} py={'0px'}>
                <Box component='form' sx={{display: 'flex', width:'100%', flexDirection:'column', bgcolor:'white', gap:'30px', py:5}}>
                    <Typography variant='subtitle' textAlign='center'>Contact Information</Typography>
                    {
                        contactTemplate.map((item, idx) => {
                            return <Box display='grid' gridTemplateColumns={{md: 'minmax(auto, 40%) auto'}} key={`contact_${idx}`}>
                                <Typography 
                                    color={(idx === 0 && nameErr && 'primary.error') || (idx === 1 && addressErr && 'primary.error') || (idx === 2 && emailErr && 'primary.error')}
                                    variant='subtitle' 
                                    paragraph='false' 
                                    sx={{pl:{md:5}}}
                                >
                                    {item}
                                </Typography>
                                <StyledTextField 
                                    variant='filled' multiline 
                                    name={idx === 0 ? 'name' : idx === 1 ? 'address' : 'email'}
                                    placeholder={contactPlaceholder[idx].placeholder} 
                                    label={contactPlaceholder[idx].label}
                                    value={idx === 0 ? clientInfo.name : idx === 1 ? clientInfo.address : clientInfo.email}
                                    sx={{mx:{md:5}, backgroundColor:'secondary.main'}} 
                                    onChange={handleTextChange}
                                    InputProps={{ disableUnderline: true }}
                                    InputLabelProps={{ shrink: true }}
                                    minRows={idx === 1 ? 2 : 1}
                                />
                            </Box>
                        })
                    }
                    <Box display='flex' justifyContent='center'>
                        <PrimaryButton text='Submit Order' onClick={onClickSubmit} />
                    </Box>
                </Box>
            </ContentBlock>
        </>
    );
};

export default OrderReview;