import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Home from './pages/home';
import About from './pages/about';
import Gallery from './pages/gallery';
import Products from './pages/products';
import theme from './theme';

import NavBar from './sharedComponents/navBar';
import Footer from './sharedComponents/footer';
import OrderTest from './pages/order/order';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <NavBar/>
      <div style={{minHeight: "100vh"}}>
        <Routes>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/products' element={<Products />} />
            <Route path='/order' element={<OrderTest />} />
        </Routes>
      </div> 
      <Footer />
    </BrowserRouter>
  </ThemeProvider>
  ,
  document.getElementById('root')
);