import React from 'react';
import './loader.css';

const Loader = () => {
    return(
        <span class='loader'></span>
    );
};

export default Loader;
